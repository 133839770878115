/* eslint-disable @next/next/no-img-element */
/* eslint-disable no-prototype-builtins */

import React, { useState, useEffect, useContext, Fragment } from "react";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import { useRouter } from "next/router";
import queryString from "query-string";
import { CloseIcon } from "../_helpers/Icons/miscellaneousIcons";
import { useOutsideClick } from "../hooks/useOutsideClick";
import useDialog from "../hooks/useDialog";
import Link from "next/link";
import { AppContext } from "../context/AppContext";
import AnimatedLink from "./AnimatedLink";
import HeaderIcons from "../_helpers/Icons/headerIcons";
import classNames from "classnames";
import { useCompare } from "../services/compare";
import find from "lodash/find";
import {
  getInitialLocation,
  getRakutenParams,
  locationArr,
} from "../_helpers/functions";

const Header = ({
  navData,
  cartItems,
  showToastMessage,
  onCartClick,
  onSearchClick,
  faveTotalItems,
}) => {
  const router = useRouter();
  const { dialogType, setDialogType } = useDialog();
  const [showToast, setShowToast] = useState(showToastMessage ?? false);
  const [showSidebar, setSidebar] = useState(false);
  const { setResetSearch } = useContext(AppContext);
  const [rakutenQuery, setRakutenQuery] = useState({
    queryExist: false,
    query: {},
  });
  const [showSecondaryNav, setShowSecondaryNav] = useState(true);
  const { totalCompareItems } = useCompare();
  const width = typeof window !== "undefined" && window.innerWidth;

  const [showAvailableLocations, setShowAvailableLocation] = useState(false);

  const [currentLocation, setCurrentLocation] = useState(() =>
    getInitialLocation(router)
  );
  const containerRef = useOutsideClick(() => {
    setSidebar(false);
  }, showSidebar);
  const [showSubMenu, setShowSubMenu] = useState({ showIdx: null });

  useEffect(() => {
    if (showSubMenu?.showIdx !== null) {
      setShowSubMenu({ subIdx: null });
      if (width < 1024) {
        setSidebar(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.asPath]);

  /**
   * Handles the scroll event to determine whether to show or hide the secondary navigation.
   * @function handleScroll
   * @global
   * @return {void}
   */
  function handleScroll() {
    if (window.scrollY > 60) {
      setShowSecondaryNav(false);
    } else {
      setShowSecondaryNav(true);
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  /* eslint-disable-next-line */
  function shareRef(...refs) {
    return (thing) => {
      refs.forEach((ref) => (ref.current = thing));
    };
  }

  useEffect(() => {
    if (showSidebar) {
      document.body.classList.add("body--modal-open");
    } else {
      document.body.classList.remove("body--modal-open");
    }
  }, [showSidebar]);
  useEffect(() => {
    const { ranMID } = router.query;
    if (dialogType.bottom && ranMID) {
      setRakutenQuery({
        queryExist: true,
        query: getRakutenParams,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query, dialogType.bottom]);

  const getUrl = (url = "") => {
    const queryExist = !isEmpty(queryString.parseUrl(url)?.query || "");
    return `${url}${
      rakutenQuery.queryExist
        ? queryExist
          ? `&${queryString.stringify(rakutenQuery.query)}`
          : `?${queryString.stringify(rakutenQuery.query)}`
        : ""
    }`;
  };

  const closeToast = () => {
    setShowToast(false);
    setDialogType({ ...dialogType, header: false, holidayHeader: false });
  };
  const diamondIconStyle = "w-6 h-6";
  const diamondIcons = {
    Square: <HeaderIcons.SquareOutline className={diamondIconStyle} />,
    Round: <HeaderIcons.RoundOutline className={diamondIconStyle} />,
    Oval: <HeaderIcons.OvalOutline className={diamondIconStyle} />,
    Cushion: <HeaderIcons.CushionOutline className={diamondIconStyle} />,
    "Elongated Cushion": (
      <HeaderIcons.ElongatedCushion className={diamondIconStyle} />
    ),
    Emerald: (
      <HeaderIcons.EmeraldOutline className={diamondIconStyle} selected />
    ),
    Asscher: (
      <HeaderIcons.AsscherOutline className={diamondIconStyle} selected />
    ),
    Pear: <HeaderIcons.PearOutline className={diamondIconStyle} selected />,
    "Rose-Cut": (
      <img
        src="/assets/images/icons/rose.svg"
        className={diamondIconStyle}
        alt="rose"
      />
    ),
    Marquise: (
      <HeaderIcons.MarquiseOutline className={diamondIconStyle} selected />
    ),
    Trillion: (
      <HeaderIcons.TrillionOutline className={diamondIconStyle} selected />
    ),
    "Old-European": (
      <HeaderIcons.OldEuropeanOutline className={diamondIconStyle} selected />
    ),
    "Old-Mine": (
      <img
        src="/assets/images/icons/old-mine-outline.svg"
        className={diamondIconStyle}
        alt="old-mine"
      />
    ),
    Radiant: (
      <HeaderIcons.RadiantOutline className={diamondIconStyle} selected />
    ),
    Princess: (
      <HeaderIcons.PrincessOutline className={diamondIconStyle} selected />
    ),
  };

  const onMenuClick = (item, idx) => {
    if (item.hasOwnProperty("subSections")) {
      setShowSubMenu((prevState) => {
        return {
          showIdx: prevState.showIdx === idx ? null : idx,
        };
      });
    }
  };
  const onMenuHoverEnter = (item, idx) => {
    if (item.hasOwnProperty("subSections")) {
      setShowSubMenu({ showIdx: idx });
    }
  };

  const onMenuHoverExit = () => {
    setShowSubMenu({
      showIdx: null,
    });
  };

  const closeMenu = (link, newTab = false) => {
    if (router.asPath === link || newTab) {
      setShowSubMenu({
        subIdx: null,
      });
      if (width < 1024) {
        setSidebar(false);
      }
    }
  };

  const getGridClass = (col) => {
    switch (col) {
      case 2:
        return "grid-cols-2";
      case 3:
        return "grid-cols-3";
      case 4:
        return "grid-cols-4";
      default:
        return "grid-cols-1";
    }
  };
  const bannerText = {
    top:
      width < 768
        ? "IN NYC, SF, DC, CHICAGO OR VIDEO CHAT "
        : "IN NYC, SF, DC, CHICAGO OR VIDEO CHAT WITH A DESIGNER",
    bottom:
      width < 768
        ? "10% OFF LAB DIAMONDS THROUGH SEP 8TH. CODE: SUNSET2024. 🍂✨"
        : "SHOP THE END OF SUMMER SALE. 10% OFF LAB DIAMONDS THROUGH SEP 8TH. CODE: SUNSET2024. 🍂✨",
  };
  return (
    <div className="flex flex-col w-full bg-white">
      {/* toast */}
      <div
        className={`max-h-0 bg-theme-blue text-white top-0 right-0 left-0 ${
          showToast && dialogType.header
            ? `${dialogType?.holidayHeader ? "h-16" : "h-10"} max-h-16`
            : "max-h-0"
        } overflow-hidden`}
      >
        <div className="flex items-center justify-between h-full">
          <div
            className={`w-full ${
              dialogType?.holidayHeader
                ? "flex flex-col items-center justify-center"
                : "m-auto"
            }`}
          >
            <Link
              legacyBehavior
              href={`/consultation${
                rakutenQuery.queryExist
                  ? `?${queryString.stringify(rakutenQuery.query)}`
                  : ""
              }`}
            >
              <a
                target="_blank"
                rel="noreferrer"
                id="header_menu_calendly_banner"
                className={classNames(
                  "text-sm text-center block flex-1 focus:outline-none uppercase",
                  // adding arbitrary values so that it doesn't break into two lines
                  width < 500 ? "text-[10px]" : ""
                )}
              >
                <span className="border-b">BOOK A CONSULTATION</span>{" "}
                {bannerText.top}
              </a>
            </Link>
            {dialogType?.holidayHeader && (
              <Link
                id="summer_sale_banner"
                legacyBehavior
                href={`/diamonds/lab-grown-diamonds/${
                  rakutenQuery.queryExist
                    ? `?show_grid=true&page=1&limit=15&${queryString.stringify(
                        rakutenQuery.query
                      )}`
                    : "?show_grid=true&page=1&limit=15"
                }`}
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  id="header_menu_holiday_banner"
                  className={classNames(
                    "text-sm text-center block flex-1 focus:outline-none uppercase",
                    // adding arbitrary values so that it doesn't break into two lines
                    width < 500 ? "text-[10px]" : ""
                  )}
                >
                  {dialogType?.holidayHeader && (
                    <p className="md:mt-0.5">{bannerText.bottom}</p>
                  )}
                </a>
              </Link>
            )}
          </div>

          <button
            className="w-6 h-6 text-white mr-1 md:mr-2"
            onClick={() => closeToast()}
          >
            <CloseIcon />
          </button>
        </div>
      </div>
      <div
        className={classNames(
          "w-full h-16 border-theme-blue lg:px-4 bg-white ",
          width < 1024
            ? showSidebar
              ? "fixed bottom-0 z-20 border-t md:w-3/4 animate-slide-in"
              : "hidden"
            : showSecondaryNav
            ? "lg:block border-b lg:animate-fade-in-down"
            : "lg:hidden"
        )}
      >
        <div className="w-full h-full flex items-center">
          {showAvailableLocations ? (
            <div
              onMouseEnter={() => setShowAvailableLocation(true)}
              onMouseLeave={() => setShowAvailableLocation(false)}
              className="animate-fade-in-down absolute -top-56 lg:-bottom-56 lg:top-auto z-10 bg-white border border-theme-blue text-theme-blue py-2 w-36 left-vw-1/12 sm:left-vw-2/12 md:left-1/6 lg:left-auto"
              onClick={(e) => {
                e.stopPropagation();
                e.target.classList.contains("child") &&
                  setCurrentLocation(e.target.textContent);
                typeof window !== "undefined" &&
                  window.sessionStorage.setItem(
                    "fd_current_studio",
                    e.target.textContent
                  );
              }}
            >
              {locationArr
                ? locationArr.map(({ id, location }) => (
                    <p
                      key={id}
                      className={classNames(
                        "px-2 cursor-pointer child mb-2 last:mb-0",
                        location === currentLocation && "underline"
                      )}
                    >
                      {location}
                    </p>
                  ))
                : null}
            </div>
          ) : null}
          {/* location nav */}
          <div
            className="w-full flex relative items-center "
            onClick={(e) => e.stopPropagation()}
          >
            <div className="absolute flex text-theme-blue w-full justify-center lg:w-auto lg:justify-start gap-1 xs:gap-2 lg:gap-4">
              <div
                onMouseEnter={() => setShowAvailableLocation(true)}
                onMouseLeave={() => setShowAvailableLocation(false)}
                className="flex items-center justify-start xs:justify-center gap-0 xs:gap-2 cursor-pointer w-fit sm:w-1/2 lg:w-fit h-11"
              >
                <HeaderIcons.LocationIcon />
                <span className="text-base lg:text-sm w-28 lg:w-[100px]">
                  {currentLocation}
                </span>
                <div
                  className={`transition-all delay-100 duration-100 ease-in-out transform ${
                    showAvailableLocations
                      ? "rotate-0 lg:rotate-180"
                      : "rotate-90"
                  }`}
                >
                  <img
                    className="w-3"
                    src="/assets/images/icons/chevron.svg"
                    alt="chevron"
                  />
                </div>
              </div>
              <a
                className="self-center flex gap-1 xs:gap-2 justify-start xs:justify-center w-1/2 lg:w-auto underline"
                href={`tel:${
                  find(
                    locationArr,
                    ({ location }) => currentLocation === location
                  )?.phone
                }`}
              >
                <HeaderIcons.PhoneIcon />
                <span className="text-base lg:text-sm ">
                  {
                    find(
                      locationArr,
                      ({ location }) => currentLocation === location
                    )?.phone
                  }
                </span>
              </a>
            </div>

            <div className="hidden lg:flex justify-center w-full items-center">
              <Link
                legacyBehavior
                href={`/${
                  rakutenQuery.queryExist
                    ? `?${queryString.stringify(rakutenQuery.query)}`
                    : ""
                }`}
              >
                <a id="header_menu_logo">
                  <img
                    src={`/assets/images/header/logo.svg`}
                    className="lg:h-8 hidden lg:block"
                    alt="Frank Darling"
                  />
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="relative">
        <div
          // style={{ zIndex: "99999 !important" }}
          onMouseLeave={() => {
            if (width >= 1024) onMenuHoverExit();
          }}
          className="absolute border-b border-theme-blue px-4 sm:px-6 lg:px-4 left-0 right-0 top-0 h-16 lg:h-12 bg-white"
        >
          <div className="flex flex-row items-center justify-between w-full h-full">
            {/* Hamburger */}
            <div className="flex gap-6 items-center lg:hidden">
              <button className="h-5 lg:hidden focus:outline-none">
                {!showSidebar ? (
                  <img
                    onClick={(event) => {
                      event.stopPropagation();
                      setSidebar(true);
                      setShowSubMenu({ subIdx: null });
                    }}
                    id="header_menu_hamburger"
                    className="h-full"
                    src="/assets/images/header/hamburger.svg"
                    alt="hamburger"
                  />
                ) : (
                  <CloseIcon
                    onClick={() => {
                      event.stopPropagation();
                      setSidebar(false);
                    }}
                    id="header_menu_close_icon"
                    className="h-full text-theme-blue"
                  />
                )}
              </button>
              <a
                className="block lg:hidden cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  // toast is causing UI shift, disable for search
                  setShowToast(false);
                  onSearchClick();
                }}
              >
                <HeaderIcons.Search className="h-5 w-5 text-theme-blue" />
              </a>
            </div>
            <a
              className="hidden lg:flex cursor-pointer text-theme-blue gap-4"
              onClick={(e) => {
                e.preventDefault();
                // toast is causing UI shift, disable for search
                setShowToast(false);
                onSearchClick();
              }}
            >
              <div className="h-fit self-center">
                <HeaderIcons.Search className="h-5 w-5 text-theme-blue " />
              </div>
              <span className="self-end text-sm">Search</span>
            </a>

            {/* LOGO */}
            <Link
              legacyBehavior
              href={`/${
                rakutenQuery.queryExist
                  ? `?${queryString.stringify(rakutenQuery.query)}`
                  : ""
              }`}
            >
              <a id="header_menu_logo" className="lg:hidden">
                <img
                  src={`/assets/images/header/mobile-logo.svg`}
                  className="h-9 sm:h-7 ml-3 block sm:hidden"
                  alt="Frank Darling"
                />
                <img
                  src={`/assets/images/header/logo.svg`}
                  className="h-7 hidden sm:block lg:hidden ml-3"
                  alt="Frank Darling"
                />
              </a>
            </Link>
            {/* Action Items */}
            <div
              className={
                width < 1024
                  ? classNames(
                      "border-theme-blue w-full md:w-3/4 transform absolute left-0 top-0 bottom-0 bg-white mt-16 z-10 h-screen",
                      showSidebar
                        ? "transition-all duration-300 translate-x-0"
                        : "-translate-x-full"
                    )
                  : ""
              }
              ref={width < 1024 ? shareRef(containerRef) : null}
            >
              <div className="flex flex-col w-full h-full py-10 space-y-6 overflow-scroll leading-10 lg:flex-row px-9 lg:overflow-hidden pb-60 lg:py-0 text-theme-blue lg:space-y-0 lg:justify-center lg:space-x-5 xl:space-x-8 lg:px-5 xl:px-4 bg-white">
                {(width < 1024
                  ? navData
                  : navData.filter((el) => !el?.mobileOnly)
                ).map((item, idx) => {
                  return (
                    <Fragment key={item.heading}>
                      {!item.hasOwnProperty("subSections") ? (
                        <Link
                          legacyBehavior
                          href={`${item?.link}${
                            rakutenQuery.queryExist
                              ? `?${queryString.stringify(rakutenQuery.query)}`
                              : ""
                          }`}
                        >
                          <a className="flex text-xl lg:text-sm w-fit">
                            {item.heading}
                          </a>
                        </Link>
                      ) : (
                        <div
                          className="flex text-xl cursor-pointer lg:text-sm w-fit"
                          onClick={() => {
                            event.stopPropagation();
                            onMenuClick(item, idx);
                          }}
                          onMouseEnter={() => {
                            if (width >= 1024) onMenuHoverEnter(item, idx);
                          }}
                        >
                          {item.heading}
                          <img
                            className={`w-3 ml-5 lg:ml-1 xl:ml-2 transform transition-all duration-500 ${
                              showSubMenu.showIdx === idx
                                ? "rotate-180"
                                : "rotate-90"
                            }`}
                            src="/assets/images/icons/chevron.svg"
                            alt="Expander Chevron"
                          />
                        </div>
                      )}
                      {item.hasOwnProperty("subSections") &&
                        showSubMenu.showIdx === idx && (
                          <div
                            className={classNames(
                              width >= 1024 &&
                                "absolute w-screen top-12 -left-5 xl:-left-8 right-0 border-b border-theme-blue p-6 bg-white"
                            )}
                          >
                            <div className="lg:flex">
                              {item?.subSections?.map((el, i) => {
                                return (
                                  <div
                                    key={`${item.heading}-${el?.subHeading}`}
                                    className="w-auto my-6 lg:my-0 first:mt-0 last:mb-0 lg:flex lg:justify-between lg:first:border-0 lg:first:pl-0 lg:last:pr-0 lg:border-l lg:border-theme-blue lg:px-4"
                                  >
                                    {el?.subHeading ? (
                                      <div className="py-2 lg:py-0 text-theme-blue min-w-fit">
                                        <h3 className="text-base font-bold">
                                          {el?.subHeading}
                                        </h3>
                                        <Link
                                          legacyBehavior
                                          href={getUrl(el?.viewAll)}
                                        >
                                          <div
                                            onClick={() => {
                                              if (item.heading === "Diamonds") {
                                                setResetSearch(true);
                                              }
                                              closeMenu(el?.viewAll);
                                            }}
                                          >
                                            <AnimatedLink
                                              className="mt-2 w-fit"
                                              cta={
                                                el?.viewAll ? "View All" : ""
                                              }
                                              href={el?.viewAll}
                                              fontClass="text-sm"
                                            />
                                          </div>
                                        </Link>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <div className="lg:ml-6">
                                      <div
                                        className={classNames(
                                          `${
                                            el?.subHeading ? "mt-6" : ""
                                          } lg:mt-0 grid gap-4 font-serif text-lg xl:text-base`,
                                          getGridClass(el.subSection)
                                        )}
                                      >
                                        {el?.links?.map((link) => {
                                          return (
                                            <Link
                                              legacyBehavior
                                              key={`${item.heading}-${el?.subHeading}-${link?.title}`}
                                              href={getUrl(link?.link)}
                                            >
                                              <a
                                                className="flex items-center w-fit"
                                                target={link?.target ?? "_self"}
                                                onClick={() =>
                                                  closeMenu(
                                                    link?.link,
                                                    !!link?.target
                                                  )
                                                }
                                              >
                                                {el?.subHeading ===
                                                  "By Shape" && (
                                                  <span className="pr-3 lg:pr-1.5">
                                                    {
                                                      diamondIcons?.[
                                                        link?.title
                                                      ]
                                                    }
                                                  </span>
                                                )}
                                                {link.title}
                                              </a>
                                            </Link>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                    </Fragment>
                  );
                })}
              </div>
            </div>
            <div className="flex flex-row space-x-1 sm:space-x-3 lg:space-x-4 ">
              {/* Search Button */}

              {/* Fave Button */}
              <Link legacyBehavior href="/wishlist">
                <a id="header_menu_wishlist" className="w-1/2">
                  <div className="relative flex items-center justify-center w-6 sm:w-8 lg:m-auto 4xl:mr-2/12 lg:w-7">
                    {faveTotalItems > 0 ? (
                      <HeaderIcons.WishlistHeart className="h-5 w-5 text-theme-blue" />
                    ) : (
                      <HeaderIcons.Heart className="h-5 w-5 text-theme-blue" />
                    )}
                    {faveTotalItems > 0 ? (
                      <span className="absolute -right-1 sm:right-0 top-1 w-3 h-3 text-[8px] font-medium bg-white text-theme-blue rounded-full flex items-center justify-center">
                        {faveTotalItems}
                      </span>
                    ) : null}
                  </div>
                </a>
              </Link>
              {/* Compare Button */}
              <Link legacyBehavior href="/comparison">
                <a id="header_menu_compare" className="w-1/2">
                  <div className="relative flex items-center justify-center w-8 sm:w-8 lg:m-auto 4xl:mr-2/12 lg:w-8">
                    {totalCompareItems > 0 ? (
                      <HeaderIcons.CompareDiamond className="h-5 w-5 text-theme-blue" />
                    ) : (
                      <HeaderIcons.CompareIcon className="h-5 w-5 text-theme-blue" />
                    )}
                    {totalCompareItems > 0 ? (
                      <span className="absolute -right-1 sm:right-0.5 top-1 w-3 h-3 text-[8px] font-medium bg-white text-theme-blue rounded-full flex items-center justify-center">
                        {totalCompareItems}
                      </span>
                    ) : null}
                  </div>
                </a>
              </Link>

              {/* Cart Button */}
              <a
                id="header_menu_cart"
                href=""
                className=""
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  onCartClick();
                }}
              >
                <div className="relative flex items-end justify-center w-6 ml-auto sm:w-8 lg:w-7 lg:m-auto 4xl:ml-2/12">
                  {cartItems > 0 ? (
                    <HeaderIcons.ActiveCart className="h-5 w-5 text-theme-blue" />
                  ) : (
                    <HeaderIcons.Cart className="h-5 w-5 text-theme-blue" />
                  )}
                  {cartItems > 0 ? (
                    <span className="absolute -right-1 sm:right-0 top-1 w-3 h-3 text-[8px] font-medium bg-white text-theme-blue rounded-full flex items-center justify-center">
                      {cartItems}
                    </span>
                  ) : null}
                </div>
              </a>
            </div>
          </div>
        </div>
        {/* Backdrop */}
        <div
          className={`${
            showSidebar ? "" : "hidden"
          } lg:hidden h-screen transform transition-all duration-300 opacity-50 bg-black absolute top-16 inset-0`}
        ></div>
      </div>
    </div>
  );
};

Header.propTypes = {
  /**
   * Header main links
   */
  navData: PropTypes.array,
  /*
   * Number of items added to the cart
   */
  cartItems: PropTypes.number,
  /*
   * Number of items added to the fave
   */
  faveTotalItems: PropTypes.number,
  /**
   * Is to show toast message
   */
  showToastMessage: PropTypes.bool,
  /**
   * Function for cart icon click
   */
  onCartClick: PropTypes.func,
};

Header.defaultProps = {
  navData: [
    {
      heading: "About",
      subSections: [
        {
          subHeading: "About",
          links: [
            {
              title: "Our Story",
              link: "/about",
            },
            {
              title: "Ethical Sourcing",
              link: "/etiquette",
            },
          ],
        },
      ],
    },
    {
      heading: "Etiquette",
      link: "/etiquette",
    },
    {
      heading: "Diamond Comparison",
      link: "/comparison/",
      mobileOnly: true,
    },
    {
      heading: "Help",
      link: "/contact",
    },
  ],
  cartItems: 0,
};

export default Header;
