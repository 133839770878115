import React from "react";
import PropTypes from "prop-types";

const QuizModal = ({ open, handleClose, ...rest }) => {
  if (!open) return null;

  return (
    <div
      {...rest}
      className="fixed bottom-0 left-0 right-0 md:inset-0 hidden md:flex items-center justify-center md:bg-black md:bg-opacity-50 z-50"
    >
      <div
        className="bg-theme-blue md:bg-white overflow-auto relative opacity-100 transition-all max-w-sm md:max-w-2xl w-full p-5 md:p-0 h-auto md:h-96 animate-popdown"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <button
          id="close-quiz-modal"
          className="absolute z-50 right-0.5 top-0.5 w-8 h-8 rounded-full flex justify-center items-center cursor-pointer"
          title="Close"
          onClick={() => handleClose()}
        >
          <svg width="20" height="20" viewBox="0 0 20 20">
            <g
              stroke="#fff"
              strokeWidth="2"
              fill="none"
              fillRule="evenodd"
              strokeLinecap="round"
            >
              <path d="M5 15L15 5M5 5l10 10" />
            </g>
          </svg>
        </button>
        <div className="flex w-full flex-auto text-white md:text-theme-blue h-full">
          <div className="flex flex-col justify-center relative z-0 md:py-14 md:px-12 w-full h-full">
            <p className="mb-4 text-lg md:text-2xl leading-5 md:leading-tight uppercase text-center">
              Take the dream ring quiz
              <span className="block md:hidden">
                GET A FREE SKETCH OF YOUR DREAM RING.
              </span>
            </p>
            <p className="hidden md:block mb-4 text-sm leading-tight text-center">
              Get a free sketch of your dream ring by our award winning design
              team in NYC.
            </p>
            <div className="w-full flex justify-center">
              <div className="w-4/5 bg-white md:bg-theme-blue text-theme-blue md:text-white flex items-center justify-center">
                <a
                  id="quiz_popup"
                  className="flex py-4 px-5 justify-center items-center uppercase"
                  href="https://frankdarling.typeform.com/to/SwTTd2#source=popup"
                >
                  Take the quiz
                  <svg
                    viewBox="0 0 1792 1792"
                    className="w-4 h-4 overflow-hidden arrow-animate"
                  >
                    <path
                      fill="currentColor"
                      d="M1171 960q0 13-10 23l-466 466q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l393-393-393-393q-10-10-10-23t10-23l50-50q10-10 23-10t23 10l466 466q10 10 10 23z"
                    />
                  </svg>
                </a>
              </div>
            </div>
          </div>
          <div className="hidden md:flex flex-col relative z-0 py-14 px-10 w-full">
            <div
              className="block absolute inset-0 overflow-hidden bg-no-repeat bg-center bg-cover opacity-100"
              style={{
                backgroundImage: "url(/assets/images/new-modal-banner.jpg)",
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

QuizModal.propTypes = {
  /**
   * If modal is open
   */
  open: PropTypes.bool,
  /**
   * Function to close modal
   */
  handleClose: PropTypes.func.isRequired,
};

export default QuizModal;
